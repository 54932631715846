<p-dialog
  [(visible)]="isVisible"
  [draggable]="false"
  [resizable]="false"
  [closable]="false"
  [modal]="true"
  [closeOnEscape]="true"
  [dismissableMask]="true"
  position="{{ bS.winWidth > 768 ? 'right' : '' }}"
  (onHide)="onHide()"
>
  <p-header>
    <button
      class="btn btn-outline-light btn-back"
      type="button"
      (click)="onHide()"
    >
      <i class="pi pi-arrow-left"></i>
      <span>&nbsp;&nbsp;{{ 'common.back' | transloco }}</span>
    </button>
  </p-header>

  <slice-search-creators
    hidden
    [multiple]="false"
    (selectedValue)="addingCreatorFromSearch($event)"
    style="width: 100%"
  ></slice-search-creators>
  <div class="slc-search-field-wrap" [hidden]="edited">
    <slc-svg-icon-search></slc-svg-icon-search>
    <input
      type="text"
      class="slc-input"
      autocomplete="off"
      placeholder="Search Creator"
      name="searchForm"
      #input
      [(ngModel)]="searchForm"
      (keyup.enter)="searchCreator(searchForm)"
    />
    <i class="pi pi-spin pi-spinner" *ngIf="isLoading"></i>
  </div>
  <div [hidden]="creatorFormArray.length > 0">
    <p class="title" *ngIf="creatorSearchArray.length > 0">
      {{ 'page-titles.client-roster' | transloco }}
    </p>
    <div *ngFor="let creator of creatorSearchArray" style="margin-bottom: 10px">
      <p-card>
        <div class="float-right">
          <button
            type="button"
            class="slc-btn slc-btn-primary slc-btn-h-36 mt-2"
            (click)="addingCreatorFromSelect(creator)"
          >
            <span>{{ 'buttons.add' | transloco }}</span>
          </button>
        </div>
        <div class="row">
          <div
            class="col-4 d-inline-flex justify-content-center align-items-center"
          >
            <i class="pi pi-star mr-2" style="font-size: 20px"></i>
            <slc-avatar
              class="slc-avatar-56"
              [userFullName]="creator.name"
              [imagePath]="creator.avatar"
            ></slc-avatar>
          </div>
          <div class="col-8 d-flex align-items-center">
            <h5>{{ creator.name }}</h5>
          </div>
        </div>
        <div class="mt-4" *ngIf="!checkIfChannelExists(creator)">
          <p class="subtitle font-black font-weight-bold">
            {{
              'reporting.common.no-connect-influencer'
                | transloco : { channel: channel }
            }}
          </p>
          <p class="subtitle mt-2">
            {{ 'reporting.common.no-connect-influencer-msg' | transloco }}
          </p>
        </div>
      </p-card>
    </div>
  </div>
  <div *ngIf="creatorFormArray.length > 0">
    <div class="border rounded p-2 my-3">
      <div class="d-flex align-items-center">
        <slc-avatar
          class="slc-avatar-56 mx-2"
          [imagePath]="creatorFormArray[0].avatar"
          [userFullName]="creatorFormArray[0].username"
        ></slc-avatar>
        <h4>{{ creatorFormArray[0].username }}</h4>
      </div>
    </div>

    <div>
      <slc-input-wrapper
        title="Username"
        class="my-2"
        [hidden]="isConnectedSocial"
      >
        <input
          type="text"
          class="slc-input"
          placeholder="Username"
          [(ngModel)]="creatorFormArray[0].socialUsername"
        />
      </slc-input-wrapper>
      <slc-input-wrapper
        title="Followers"
        class="my-2"
        [hidden]="isConnectedSocial"
      >
        <p-inputNumber
          locale="en-US"
          styleClass="w-100"
          inputId="Followers"
          [(ngModel)]="creatorFormArray[0].followersCount"
          mode="decimal"
          class="w-100"
          (onInput)="validateFollowers($event)"
        >
        </p-inputNumber>
        <p class="text-danger my-2 p-12" *ngIf="showError">
          Please ensure the follower count is greater than zero
        </p>
      </slc-input-wrapper>
    </div>
    <div class="mt-4 mb-2">
      <h6 class="font-weight-bold my-2 text-center">
        {{ 'reporting.common.sow-title' | transloco }}
      </h6>
      <p class="mt-1">{{ 'reporting.common.sow-subtitle' | transloco }}</p>
    </div>
    <div class="my-2 border rounded py-1 px-2">
      <h6 class="font-weight-bold my-2">
        {{ 'reporting.common.sow' | transloco }}
      </h6>
      <div *ngFor="let sow of creatorFormArray[0].postList" class="mb-3">
        <div class="d-flex flex-sm-row flex-column">
          <slc-input-wrapper
            title="{{ 'enums.post-types.' + sow.postType | transloco }}"
          >
            <div class="form-sow">
              <p-inputNumber
                locale="en-US"
                styleClass="w-100"
                inputId="Followers"
                [(ngModel)]="sow.quantity"
                mode="decimal"
                class="w-100"
              >
              </p-inputNumber>
            </div>
          </slc-input-wrapper>
          <slc-input-wrapper title="Price">
            <div class="form-sow">
              <p-inputNumber
                locale="en-US"
                inputId="Followers"
                styleClass="w-100"
                [(ngModel)]="sow.price"
                mode="decimal"
                class="w-100"
              >
              </p-inputNumber>
            </div>
          </slc-input-wrapper>
        </div>
        <div class="my-2 mr-2 d-flex flex-row-reverse">
          <p>
            {{ 'manager-invoicing.subtotal' | transloco }} : IDR
            {{ calculateSubTotal(sow.quantity, sow.price) | number : '1.0-2' }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <p-footer>
    <div class="d-flex justify-content-between my-2">
      <p>
        {{ 'manager-invoicing.subtotal' | transloco }}
      </p>
      <p>IDR {{ calculateTotalNet() ?? 0 | number : '1.0-2' }}</p>
    </div>
    <button
      class="slc-btn slc-btn-h-56 slc-btn-primary w-100 m-0"
      type="button"
      (click)="onSubmit()"
      [disabled]="disableSubmit()"
    >
      <span>{{ buttonTitle }}</span>
    </button>
  </p-footer>
</p-dialog>
