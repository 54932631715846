import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';
import { AuthStateService } from '@slice-services/auth-state.service';
import { SearchHttpService } from '@slice-services/search-http.service';
import { MessageService } from 'primeng/api';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import { Channels, PostTypes } from '@slc-libs/enums';
import { getEmailMock, getIdMock } from '@slc-libs/mocks';
import { BreakpointsService } from '@slc-libs/services';

import { AbstractSubscriberComponent } from '@slice-shared/abstract-classes/subscriber';
import {
  CreatorSearchDetail,
  CreatorSearchParams,
  XpressReportCreator,
  XpressReportPayload,
} from '@slice-interfaces/xpress-report.model';

@Component({
  selector: 'slice-add-creator-sow',
  templateUrl: './add-creator-sow.component.html',
  styleUrls: ['./add-creator-sow.component.scss'],
})
export class AddCreatorSowComponent
  extends AbstractSubscriberComponent
  implements OnInit, OnDestroy
{
  @Input() channel: Channels;
  @Input() buttonTitle?: string = 'Add to Report';
  @Input() showConnectCreator? = true;
  @Input() creatorList: XpressReportCreator[] = [];
  @Input() creatorListAPI = '';
  @Input() edited: any = null;

  @Output() closed = new EventEmitter<XpressReportCreator[]>();
  @ViewChild('input', { static: true }) Input: ElementRef;

  public isVisible = true;
  public isLoading = false;
  public isConnectedSocial = false;
  public searchForm: any;
  public showError = false;
  public creatorSearchArray: CreatorSearchDetail[];
  public creatorFormArray: XpressReportCreator[] = [];

  searchCreators: any;
  userInput: Subject<string> = new Subject<string>();

  constructor(
    public tS: TranslocoService,
    public bS: BreakpointsService,
    private messageS: MessageService,
    private authStateS: AuthStateService,
    private searchHttpS: SearchHttpService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
    this.inputEvent();
    if (this.edited) {
      if (this.edited.socialUsername && this.edited.followersCount > 0) {
        this.isConnectedSocial = true;
      }
      this.creatorFormArray = [this.edited];
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.creatorFormArray = [];
  }

  private initForm(): void {
    this.creatorFormArray = [];
    this.creatorSearchArray = [];
  }

  /* This method associate to filter query from user input */
  inputEvent() {
    fromEvent(this.Input.nativeElement, 'keyup') // event from keyboard
      .pipe(
        map(
          (evt: any) => evt.target.value,
        ) /* map different value from user input */,
        // filter(res => res.length > 2),                   /* text length must be > 2 chars */
        debounceTime(750) /* emit after 1s of silence */,
        distinctUntilChanged() /* emit only if data changes since the last emit */,
      )
      .subscribe((keyword: string) => this.searchCreator(keyword));
  }

  public searchCreator(keyword: string): void {
    if (this.creatorFormArray.length > 0) {
      this.creatorFormArray = [];
      this.isConnectedSocial = false;
    }
    this.isLoading = true;
    const params: CreatorSearchParams = {
      page: 0,
      size: 10,
      name: keyword,
      roles: ['creator'],
    };
    if (this.creatorListAPI) {
      this.searchHttpS.hitCustomURL(this.creatorListAPI, params).subscribe(
        res => {
          if (res?.content !== undefined && res?.content !== null) {
            this.creatorSearchArray = res.content;
          }
          this.isLoading = false;
        },
        error => {
          this.isLoading = false;
        },
      );
    } else {
      this.searchHttpS
        .findCreatorAgencyId(
          this.authStateS.getAuthenticatedUser()?.agencyOwned as string,
          // this.authStateS.getAuthenticatedUser()?.userId as string,
          params,
          false,
        )
        .subscribe(
          res => {
            if (res?.content !== undefined && res?.content !== null) {
              this.creatorSearchArray = res.content;
            }
            this.isLoading = false;
          },
          e => {
            this.isLoading = false;
          },
        );
    }
  }

  public addingCreatorFromSearch(creator: any) {
    if (creator) {
      this.creatorFormArray.push(<XpressReportCreator>{
        userId: getIdMock(),
        email: getEmailMock(),
        channel: this.channel as unknown,
        username: creator,
        followersCount: 69,
        engagementRate: 69,
        cpr: 42,
        cpv: 42,
        cpi: 42,
        cpe: 42,
        postList: [],
        postPrices: [],
        totalPrice: 0,
      });
    }
  }

  public addingCreatorFromSelect(creator: CreatorSearchDetail) {
    // check if creator already selected inside form
    if (
      this.creatorList?.length &&
      this.creatorList.findIndex(c => c.userId === creator.userId) !== -1
    ) {
      this.messageS.add({
        summary: this.tS.translate('campaign.message.cannot-add-creator'),
        severity: 'error',
        life: 8 * 1000,
        detail: this.tS.translate('campaign.message.existed-creator-form'),
      });
      return;
    }

    // check if creator already selected inside dialog
    const existedAccountIndex = this.creatorFormArray.findIndex(
      account => account.username === creator.name,
    );
    if (existedAccountIndex > -1) {
      this.messageS.add({
        summary: this.tS.translate('campaign.message.cannot-add-creator'),
        severity: 'error',
        life: 8 * 1000,
        detail: this.tS.translate('campaign.message.existed-creator'),
      });
      return;
    }

    // check if creator has account for this channel
    const accountIndex = creator.socialAccounts.findIndex(
      account => account.channel === this.channel,
    );

    // All validations passed, add creator to the form array
    const selectedAccount = creator.socialAccounts[accountIndex];
    const postListGenerate: XpressReportCreator =
      XpressReportPayload.generateCreatorPostList(
        selectedAccount?.channel ?? (this.channel as any),
        {
          avatar: creator.avatar,
          userId: creator.userId,
          email: creator.email,
          username: creator.name,
          socialUsername: selectedAccount?.username ?? '',
          channel: selectedAccount?.channel ?? (this.channel as any),
          followersCount: selectedAccount?.followers ?? 0,
          engagementRate: selectedAccount?.stats?.er ?? 0,
          cpr: selectedAccount?.stats?.cpr ?? 0,
          cpv: selectedAccount?.stats?.cpv ?? 0,
          cpi: selectedAccount?.stats?.cpi ?? 0,
          cpe: selectedAccount?.stats?.cpe ?? 0,
          postPrices: creator.postPrices,
          postList: [],
          totalPrice: 0,
        },
      );
    if (selectedAccount) {
      this.isConnectedSocial = true;
    }
    if (!selectedAccount) {
      this.isConnectedSocial = false;
    }
    this.creatorFormArray.push(postListGenerate);
  }

  public deleteCreatorId(userId?: string) {
    if (userId) {
      this.creatorFormArray = this.creatorFormArray.filter(
        creator => creator.userId !== userId,
      );
    } else {
      this.creatorFormArray.pop();
    }
  }

  onHide(): void {
    this.creatorFormArray = [];
    this.creatorSearchArray = [];
    this.closed?.emit(undefined);
  }

  calculateTotalNet(): number {
    return this.creatorFormArray[0]?.postList
      .filter(item => item.quantity > 0) // Filter out items with quantity 0
      .reduce((total, item) => total + item.quantity * item.price, 0); // Sum up quantity * price
  }
  calculateQuantity(): number {
    return this.creatorFormArray[0].postList.reduce(
      (total, post) => total + post.quantity,
      0,
    );
  }
  calculateSubTotal(qty: number, price: number): number {
    return qty * price;
  }

  onSubmit(): void {
    if (this.creatorFormArray.length > 0) {
      this, (this.creatorFormArray[0].totalPrice = this.calculateTotalNet());
      this.closed?.emit(this.creatorFormArray);
    }
  }

  checkIfChannelExists(creator: any): boolean {
    if (this.showConnectCreator) {
      return (
        creator?.socialAccounts?.some(
          (account: { channel: Channels }) => account.channel === this.channel,
        ) ?? false
      );
    } else {
      return true;
    }
  }

  validateFollowers(event: any) {
    const value = event.value;
    this.showError = value <= 0;
  }

  disableSubmit(): boolean {
    if (
      this.creatorFormArray[0]?.socialUsername &&
      this.calculateQuantity() > 0
    ) {
      if (this.isConnectedSocial) {
        //if connect social dont check folowers
        return false;
      } else {
        //if not connect social check folowers
        if ((this.creatorFormArray[0]?.followersCount as number) > 0) {
          return false;
        }
        return true;
      }
    }
    return true;
  }
}
